import request from '@/utils/request'

// 门店 库存列表
export function getIndexList(data) {
  return request({
    url: '/customerAdmin/customer-goods/index',
    method: 'post',
    data,
  })
}

// 录入门店库存
export function ImportAdd(data) {
  return request({
    url: '/customerAdmin/customer-goods/add',
    method: 'post',
    data,
  })
}
