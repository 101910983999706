<template>
  <el-dialog
    :modal="false"
    title="录入门店库存"
    :visible.sync="showDialog"
    width="900px"
    top="15vh"
    :close-on-click-modal="false"
    destroy-on-close
  >
    <div class="orderTest-container">
      <div style="margin-bottom: 15px">
        <client-search
          ref="clientSearch"
          :popper-class="'selectDC'"
          @select-id="selectClient"
        ></client-search>
      </div>
      <el-table stripe :data="list">
        <el-table-column
          align="center"
          label="盘点商品"
          prop="goods_name"
          width="220px"
        >
          <template #default="{ row }">
            <goods-search
              ref="goodsSearch"
              :f-key="row.goods_name"
              @add-rows="addRows"
              @select-goods-all="selectGoods($event, row)"
            ></goods-search>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="规格"
          prop="specs"
        ></el-table-column>
        <el-table-column align="center" label="盘点数量" prop="" width="350px">
          <template #default="{ row }">
            <div v-if="row.goods_id !== ''">
              <span
                v-for="(unitItem, unitIndex) in row.unit_data"
                :key="unitIndex"
              >
                <el-input
                  v-model="unitItem.num"
                  type="number"
                  :min="0"
                  style="width: 80px"
                ></el-input>
                {{ unitItem.unit_name }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" prop="">
          <template #default="{ $index, row }">
            <el-button
              type="text"
              @click.native.prevent="handleDeleteRow($index, row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div
      slot="footer"
      class="dialog-footer"
      style="margin-top: 5px; text-align: right"
    >
      <el-button type="primary" @click="handleSave">保存</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import { ImportAdd } from '@/api/storyInventory'
  import ClientSearch from '@/baseComponents/clientSearch'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  export default {
    name: 'Add',
    components: { ClientSearch, GoodsSearch },
    data() {
      return {
        showDialog: false,
        cust_id: '',
        list: [
          {
            goods_id: '',
            specs: '',
            unit_data: [],
          },
        ],
      }
    },
    computed: {},
    watch: {
      showDialog(old) {
        if (!old) {
          this.list = [
            {
              goods_id: '',
              specs: '',
              unit_data: [],
            },
          ]
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      selectClient(val) {
        this.cust_id = val
      },
      selectGoods(val, row) {
        console.log(val)
        let v = JSON.parse(JSON.stringify(val))
        row.goods_id = v.goods_id
        row.specs = v.specs
        v.arr_unit.forEach((item) => {
          item.num = ''
        })
        row.unit_data = v.arr_unit
        if (this.list[this.list.length - 1].goods_id !== '') {
          this.addRow()
        }
      },
      addRow() {
        this.list.push({
          goods_id: '',
          goods_name: '',
          unit_data: [],
          specs: '',
        })
      },
      addRows(val) {
        let v = JSON.parse(JSON.stringify(val))
        v.forEach((item) => {
          item.arr_unit.forEach((i) => {
            i.num = ''
          })
          this.list.push({
            goods_id: item.goods_id,
            goods_name: item.goods_name,
            unit_data: item.arr_unit,
            specs: item.specs,
          })
        })
      },
      handleDeleteRow(index, row) {
        if (this.list.length == 1) {
          this.$message.error('不能删除最后一行')
        } else {
          this.list.splice(index, 1)
        }
      },
      close() {
        this.showDialog = false
      },
      handleSave() {
        let goods_data = []
        this.list.forEach((item) => {
          if (item.goods_id !== '') {
            goods_data.push(item)
          }
        })
        console.log(goods_data)
        let endData = {
          cust_id: this.cust_id,
          goods_data: JSON.stringify(goods_data),
        }
        ImportAdd(endData).then((res) => {
          this.$message.success(res.msg)
          this.$emit('refresh')
          this.close()
        })
      },
    },
  }
</script>
<style lang="scss" scoped></style>
