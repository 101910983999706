<!-- NAME[epic=销售] 门店库存 -->
<template>
  <div class="orderTest-container">
    <el-form :model="form" inline>
      <el-form-item label="">
        <el-select
          v-model="form.add_id"
          clearable
          style="width: 140px"
          placeholder="请选择盘点人"
        >
          <el-option
            v-for="(i, idx) in staffList"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <brand-select
          ref="brandSelect"
          @brand-select-change="setBrand"
        ></brand-select>
      </el-form-item>
      <el-form-item label="">
        <goods-search
          ref="goodsSearch"
          :is-table="false"
          @select-goods="selectGoods"
        ></goods-search>
      </el-form-item>
      <el-form-item label="">
        <client-search
          ref="clientSearch"
          :popper-class="'selectDC'"
          @select-id="selectClient"
        ></client-search>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="
            () => {
              form.pageSize = 10
              form.pageNo = 1
              fetchData()
            }
          "
        >
          查询
        </el-button>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleImport">录入门店库存</el-button>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleExport">导出</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" stripe :data="list">
      <!-- 序号 -->
      <el-table-column align="center" type="index" width="50">
        <!-- <template slot="header">
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="index"
                :label="item.label"
              ></el-checkbox>
            </el-checkbox-group>
            <el-button slot="reference" type="text">
              <vab-remix-icon icon="settings-line" />
            </el-button>
          </el-popover>
        </template> -->
      </el-table-column>
      <!-- 选择框 -->
      <el-table-column
        align="center"
        type="selection"
        width="50"
      ></el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        width=""
        align="center"
      ></el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <add ref="add" @refresh="fetchData"></add>
  </div>
</template>
<script>
  import _ from 'lodash'
  import { getStaffList } from '@/api/setPrice'
  import { getIndexList } from '@/api/storyInventory'
  import BrandSelect from '@/baseComponents/brandSelect'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import ClientSearch from '@/baseComponents/clientSearch'
  import Add from './components/add'
  import { downloadFile } from '@/api/Employee'

  export default {
    name: 'Store',
    components: {
      Add,
      BrandSelect,
      GoodsSearch,
      ClientSearch,
    },
    data() {
      return {
        loading: false,
        staffList: [],
        brandList: [],
        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        form: {
          add_id: '', //盘点人ID
          brand_id: '', //品牌ID
          goods_name: '', //产品名称或简拼
          goods_id: '',
          cust_name: '', //终端名称简拼或电话
          cust_id: '',
          pageNo: 1, //页码
          pageSize: 10, //每页几行 -1是所有
        },
        list: [],
        checkList: [
          '客户名称',
          '老板姓名',
          '电话',
          '商品名称',
          '规格',
          '最近盘点时间',
          '盘点人',
          '库存数量',
          '生产日期',
        ],
        columns: [
          {
            order: 1,
            label: '客户名称',
            prop: 'cust_name',
            width: '',
          },
          {
            order: 2,
            label: '老板姓名',
            prop: 'cust_boss',
            width: '',
          },
          {
            order: 3,
            label: '电话',
            prop: 'cust_mobile',
            width: '',
          },
          {
            order: 4,
            label: '商品名称',
            prop: 'goods_name',
            width: '',
          },
          {
            order: 5,
            label: '规格',
            prop: 'specs',
            width: '',
          },
          {
            order: 6,
            label: '最近盘点时间',
            prop: 'update_at',
            width: '',
          },
          {
            order: 7,
            label: '盘点人',
            prop: 'add_name',
            width: '',
          },
          {
            order: 8,
            label: '库存数量',
            prop: 'goods_num',
            width: '',
          },
          {
            order: 9,
            label: '生产日期',
            prop: 'remark',
            width: '',
          },
        ],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {},
    created() {
      this.fetchData()
      getStaffList().then((res) => {
        this.staffList = res.data
      })
    },
    mounted() {},
    activated() {
      this.fetchData()
    },
    methods: {
      async fetchData() {
        this.loading = true
        let { data, msg, code, totalCount } = await getIndexList(this.form)
        this.list = data
        this.total = totalCount
        this.loading = false
      },
      setBrand(val) {
        if (val) {
          this.form.brand_id = val
        }
      },
      selectGoods(val) {
        this.form.goods_id = val
      },
      selectClient(val) {
        this.form.cust_id = val
      },
      handleImport() {
        this.$refs.add.showDialog = true
      },
      handleExport() {
        downloadFile(
          '/customerAdmin/customer-goods/export-index',
          '门店库存.xlsx',
          this.form
        )
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
    },
  }
</script>
<style lang="scss" scoped></style>
