var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        title: "录入门店库存",
        visible: _vm.showDialog,
        width: "900px",
        top: "15vh",
        "close-on-click-modal": false,
        "destroy-on-close": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "orderTest-container" },
        [
          _c(
            "div",
            { staticStyle: { "margin-bottom": "15px" } },
            [
              _c("client-search", {
                ref: "clientSearch",
                attrs: { "popper-class": "selectDC" },
                on: { "select-id": _vm.selectClient },
              }),
            ],
            1
          ),
          _c(
            "el-table",
            { attrs: { stripe: "", data: _vm.list } },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "盘点商品",
                  prop: "goods_name",
                  width: "220px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("goods-search", {
                          ref: "goodsSearch",
                          attrs: { "f-key": row.goods_name },
                          on: {
                            "add-rows": _vm.addRows,
                            "select-goods-all": function ($event) {
                              return _vm.selectGoods($event, row)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "规格", prop: "specs" },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "盘点数量",
                  prop: "",
                  width: "350px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.goods_id !== ""
                          ? _c(
                              "div",
                              _vm._l(
                                row.unit_data,
                                function (unitItem, unitIndex) {
                                  return _c(
                                    "span",
                                    { key: unitIndex },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "80px" },
                                        attrs: { type: "number", min: 0 },
                                        model: {
                                          value: unitItem.num,
                                          callback: function ($$v) {
                                            _vm.$set(unitItem, "num", $$v)
                                          },
                                          expression: "unitItem.num",
                                        },
                                      }),
                                      _vm._v(
                                        " " + _vm._s(unitItem.unit_name) + " "
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "操作", prop: "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var $index = ref.$index
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.handleDeleteRow($index, row)
                              },
                            },
                          },
                          [_vm._v(" 删除 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-top": "5px", "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSave } },
            [_vm._v("保存")]
          ),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }